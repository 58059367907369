<template>
  <v-chart class="chart" :option="option" />
</template>

<script>
import { use, graphic } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { LineChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
  GridComponent,
} from 'echarts/components';
import VChart, { THEME_KEY } from 'vue-echarts';

use([
  CanvasRenderer,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
  GridComponent,
]);

const numeral = require('numeral');

export default {
  props: {
    chartData: {
      type: Object,
      default: null,
    },
  },
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: 'auto',
  },
  methods: {
    TruncateToDecimals2(num, type, dec = 2) {
      //   if (!dec) dec = 2;
      const numberFormat = parseFloat(num.toFixed(10));
      const d = numberFormat.toString().split('.');
      if (type == 1) {
        return `${numeral(d[0]).format(0, 0)}`;
      }
      return `${numeral(d[0]).format(0, 0)}${
        d[1] ? `.${d[1].slice(0, dec)}` : ''
      }`;
    },
  },
  computed: {
    option() {
      const self = this;
      const option = {
        color: ['#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00'],
        title: {
          show: false,
          text: 'Gradient Stacked Area Chart',
        },
        tooltip: {
          trigger: 'axis',

          formatter: (params) => {
            const label = params[0].name;
            const value = self.TruncateToDecimals2(
              parseFloat(params[0].data),
              '',
              2,
            );
            // const percent = self.TruncateToDecimals2(
            //   parseFloat(params.percent),
            //   "",
            //   2
            // );
            return `${label} <br /> Số Tiền: ${value} VND`;
          },
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985',
            },
          },
        },
        legend: {
          show: false,
          data: ['Doanh Thu'],
        },
        toolbox: {
          show: false,
          feature: {
            saveAsImage: {},
          },
        },
        grid: {
          left: '2%',
          right: '5%',
          bottom: '5%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: self.chartData.y,
          },
        ],
        yAxis: [
          {
            type: 'value',
            formatter: (params) => {
              // console.log(params);
            },
          },
        ],
        series: [
          {
            name: 'Doanh Thu',
            type: 'line',
            stack: 'Total',
            smooth: true,
            lineStyle: {
              width: 5,
            },
            showSymbol: true,
            symbolSize: 20,
            label: {
              show: false,
              position: 'top',
            },
            areaStyle: {
              opacity: 0.8,
              color: new graphic.LinearGradient(0, 2, 1, 1, [
                {
                  offset: 0,
                  color: 'rgb(0, 140, 255)',
                },
                {
                  offset: 0.9,
                  color: 'rgb(255, 255, 255)',
                },
              ]),
            },
            itemStyle: {
              normal: {
                color: new graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: 'rgb(0, 229, 149)',
                  },
                  {
                    offset: 1,
                    color: 'rgb(0, 140, 255)',
                  },
                ]),

                shadowBlur: 5,
                shadowColor: 'rgba(0, 0, 0, .12)',
                shadowOffsetX: 5,
                shadowOffsetY: 5,
              },
            },
            emphasis: {
              focus: 'series',
            },
            data: self.chartData.x,
          },
        ],
      };

      return option;
    },
  },
};
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>
